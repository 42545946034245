import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Wave from "src/components/modules/nonbmaWave"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"

const waveData = import("./data/data.json");

export default function spatide() {

  return(
    <HomepageLayout>
      <Helmet>

        <style type="text/css">{`
          .wave-module--wave button.wave-module--waveBtn{
                	animation: 1.5s normal 0.5s 1;
                 animation-name: slideInFromLeft;
                	animation-fill-mode: forwards;

                }
                @keyframes slideInFromLeft {
                	from {
                		background-position: right bottom;
                	}
                	to {
                		background-position: 0 0;
                		background-color: #096bfb;
                	}
                }
            #ctadisclosuresearch-module--ctadisclaimersearch {
              width: 75%;
            }

            .wave-module--wave h1 {
              font-size: 24px;
            }

            .wave-module--wave .wave-module--header img {
              padding: 5px 0 5px 10px;
              width: 40px;
            }

            .wave-module--wave ul.wave-module--bullets li:nth-child(3) {
              flex: 0 1 50%;
            }

              .wave-module--wave ul.wave-module--bullets {
                gap: 5px 0;
              }

             .wave-module--wave ul.wave-module--bullets li:nth-child(4) {
               place-self: baseline;
             }
      `}
         </style>

      <title>Freshy Search - freshysearch.com</title></Helmet>
      <Wave data={waveData}><CtaDisclosureSearch data={waveData}></CtaDisclosureSearch></Wave>
    </HomepageLayout>
  )
}
